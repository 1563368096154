import React from 'react'
import { Link } from 'gatsby'
import Head from '../components-en/head'
import Layout from '../components-en/layout'
import '../styles/grid.scss'
import '../styles/index.scss'
import notFound from "../images/404.svg"

const NotFound = () => {
    return (
        <Layout>
            <Head title=":( We couldn't find this page" />
            <div className="row blog-index">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="hero-text">
                <h1>We couldn't find this page. <span role="img" aria-label="emoji">😵</span></h1>
                <p>It seems like you came wrong. You better go back to <Link to="/en">home page</Link>. <Link to="contact">Contact</Link> us if you need help.</p>
                <h5><Link to="/contact" className="cta-blue-button">Bize Yaz</Link></h5>
              </div>
            </div>
            <div className="col-6 center">
             
              <img src={notFound} alt="Not Found"/> 
              
            </div>
          </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default NotFound